<template>
  <div class="flex flex-col w-full">
    <VendorItemCard
      v-for="vendorId in sortedIds"
      :key="vendorId"
      class="vendor-list w-full flex flex-row space-x-4 p-4 bg-gray-300 rounded-10 bg-opacity-40 mb-2"
    >
      <template #info>
        <VendorInfoItem :vendor="_payload[vendorId].vendor" show-rate />
      </template>

      <template #samples>
        <slot v-bind="_payload[vendorId].samples" />
      </template>
    </VendorItemCard>
  </div>
</template>

<script>
import { cloneDeep } from 'lodash';

import VendorInfoItem from '@/components/Vendors/Matching/Blocks/VendorInfoItem.vue';
import VendorItemCard from '@/components/Vendors/Matching/Blocks/VendorItemCard.vue';

export default {
  name: 'PortfolioSamplesListByVendors',
  components: {
    VendorInfoItem,
    VendorItemCard,
  },
  props: {
    payload: {
      type: Array,
      default: () => [],
    },
  },
  computed: {
    _payload() {
      return cloneDeep(this.payload).reduce((acc, value) => {
        if (value?.vendor?.id) {
          acc[value.vendor.id] = {
            ...value,
            vendor: {
              ...value.vendor,
              name: [value.vendor.first_name, value.vendor.last_name].join(' '),
            },
          };
        }
        return acc;
      }, {});
    },

    sortedIds() {
      return this.payload.map((o) => o.vendor.id);
    },
  },
};
</script>

<style lang="scss">
::v-deep {
  .right-container {
    margin-right: 30px;
  }
}
</style>
